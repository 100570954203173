import React from 'react'
import styled from 'styled-components'
import Head from 'next/head'

import { Header } from './Header'
import { down } from 'styled-breakpoints'

interface Props {
  animatedLogo?: boolean
  transparentHeader?: boolean
  noBottomPadding?: boolean
}

export const Page: React.FunctionComponent<Props> = ({ children, transparentHeader, animatedLogo, noBottomPadding }) => {
  return (
    <Container noBottomPadding={noBottomPadding}>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&family=Libre+Barcode+128+Text&family=Work+Sans&display=swap" rel="stylesheet" />
        <link
          rel="preload"
          href="/fonts/SomeType-Mono/SometypeMono-Regular.ttf"
          as="font"
          crossOrigin=""
        />
      </Head>

      <Header
        transparent={transparentHeader}
        animated={animatedLogo}
        hideMenuOnScroll
      />

      <Contents>{children}</Contents>
    </Container>
  )
}

export default Page

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 100px var(--gutter);

  ${down('large')} {
    padding: 150px var(--gutter);
  }

  ${down('small')} {
    padding: 160px var(--gutter);
  }

  ${(props: { noBottomPadding?: boolean }) => props.noBottomPadding && 'padding-bottom: 0 !important;'}
`

const Contents = styled.div`
  width: 100%;
`
