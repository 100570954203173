import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import SvgMutagen from 'shared/svg/BigMutagen'
import { FlexBox } from 'react-styled-flex'
import { down } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'
import { motion } from 'framer-motion'

interface Props {
  transparent?: boolean
  animated?: boolean
  hideMenuOnScroll?: boolean
}

export const Header: React.FunctionComponent<Props> = () => {{  /*transparent, animated, hideMenuOnScroll = false*/ }
  const isMedium = useBreakpoint(down('medium'))
  const isLarge = useBreakpoint(down('large'))
  // const isSmall = useBreakpoint(down('small'))
  const gap = isMedium ? '2em' : (isLarge ? '1em' : '3em')

  // const [hasScrolled, setHasScrolled] = React.useState(true)
  // const [hideMenu /*, setHideMenu*/] = React.useState(false)
  // const { scrollY } = useViewportScroll()
  // const controls = useAnimation()
  // const controlsContainer = useAnimation()
  // const menuAnimation = useAnimation()
  // const xVelocity = useVelocity(scrollY)

  // const animateLogo = !(isSmall || isMedium) && animated && typeof window !== 'undefined'
  const animateLogo = false

  // scrollY.onChange(value => {
  //   if (value > 1) {
  //     setHasScrolled(true)
  //     if (xVelocity.getVelocity() > 0) {
  //       setHideMenu(false)
  //     } else {
  //       setHideMenu(true)
  //     }
  //   } else {
  //     setHasScrolled(false)
  //     setHideMenu(false)
  //   }
  // })

  // React.useEffect(() => {
  //   if (hideMenu) {
  //     if (hideMenuOnScroll) {
  //       menuAnimation.start({
  //         opacity: 0,
  //       } as any)
  //     }
  //   } else {
  //     menuAnimation.start({
  //       opacity: 1,
  //     } as any)
  //   }
  //   // eslint-disable-next-line
  // }, [hideMenu, hideMenuOnScroll])

  // React.useEffect(() => {
  //   if (animateLogo) {
  //     if (hasScrolled) {
  //       controls.start({
  //         width: 300,
  //         height: 50,
  //         position: 'relative',
  //         'pointer-events': 'unset',
  //         top: 0,
  //       } as any)
  //       controlsContainer.start({
  //         y: 0,
  //       } as any)
  //     } else {
  //       controls.start({
  //         width: 'calc(100vw - 2 * 84px)',
  //         height: 300,
  //         'pointer-events': 'none',
  //         top: '-50px',
  //       } as any)
  //       controlsContainer.start({
  //         y: -50,
  //       } as any)
  //     }
  //   } else {
  //     controlsContainer.start({
  //       y: 0,
  //     } as any)
  //     controls.start({
  //       height: 50,
  //       width: 300,
  //       position: 'relative'
  //     } as any)
  //   }
  //   // eslint-disable-next-line
  // }, [hasScrolled, isSmall, isMedium, animateLogo])

  return (
    <Container>
      <Link href="/" passHref>
        <motion.a style={{ height: 50, width: '300px' }} /*animate={controlsContainer}*/>
          <SvgMutagen
            width="300px"
            height="50px"
            style={animateLogo ? { position: 'absolute' } : {}}
            initial={(animateLogo ? {
              width: 'calc(100vw - 2 * 84px)',
              height: 300,
              top: '-50px'
            } : {}) as any}
            // animate={controls}
          />
        </motion.a>
      </Link>
      
      <motion.div initial={{ opacity: 1, zIndex: 1, position: 'relative' }}>
        <MenuContainer center gap={gap}>
          <Link href="/gallery" passHref>
            <HeaderLink>Gallery</HeaderLink>
          </Link>

          <Link href="/explore" passHref>
            <HeaderLink>Explore</HeaderLink>
          </Link>

          <Link href="/artists" passHref>
            <HeaderLink>Artists</HeaderLink>
          </Link>

          <Link href="/collection" passHref>
            <HeaderLink>Wallet</HeaderLink>
          </Link>
        </MenuContainer>
      </motion.div>
    </Container>
  )
}

const MenuContainer = styled(FlexBox)`
  z-index: 1;
  ${down('medium')} {
    margin-top: 1rem;
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  padding: 25px var(--gutter);

  font-family: 'Archivo', sans-serif;
  text-transform: uppercase;

  ${down('medium')} {
    flex-direction: column;
  }
`

const HeaderLink = styled.a`
  color: black;
  text-decoration: none;
  font-size: 1.5em;
  cursor: pointer;

  ${down('small')} {
    font-size: 1em;
  }
`
